<template>
  <div>
    <ModalMailAddresses
      v-if="showMailAddresses"
      :showModal="showMailAddresses"
      :onClickCancel="hideModal"
      :mailadresses="selectedMaiAddresses"
      :mailKey="selectedKey"
      @updateMailAddress="updateMailAddress"
    />

    <ui-component-modal
      modalTitle="Edit mail settings"
      modalSize="large"
      :isSaving="isSaving"
      :isSavingSuccess="isSavingSuccess"
      :isSavingError="isSavingError"
      :hideFooterButtons="isSavingError || isSavingSuccess"
      :onClickCancel="onClickCancel"
      :onClickSave="saveMailSettings"
      :showModal="showModal"
      @closeModal="onClickCancel"
    >
      <template slot="modalTitle"></template>
      <template v-slot:content>
        <table class="table is-fullwidth is-striped">
          <thead>
            <tr>
              <th>Meeting type</th>
              <th>Action</th>
              <th>Receive mail</th>
              <th>Email address</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(setting, index) in mSettings.filter(
                (s) => s.ChannelId === selectedChannel
              )"
              :key="index"
            >
              <td>{{ setting.MeetingtypeId | getMeetingtypeName }}</td>
              <td>{{ setting.ChangeType }}</td>
              <td>
                <div class="tabs is-toggle is-small has-text-right">
                  <ul>
                    <li :class="{ 'is-active': setting.ReceiveMail }">
                      <a @click="setting.ReceiveMail = true">
                        <span>Yes</span>
                      </a>
                    </li>
                    <li :class="{ 'is-active': !setting.ReceiveMail }">
                      <a @click="setting.ReceiveMail = false">
                        <span>No</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
              <td>
                <div
                  class="tags has-text-right"
                  v-if="setting.EmailAddress && setting.EmailAddress.length > 0"
                >
                  <span
                    v-for="(address, index) in setting.EmailAddress.split(
                      ','
                    ).filter((m) => m.length > 0)"
                    :key="index"
                    class="tag is-link"
                    >{{ address }}
                  </span>
                </div>
              </td>
              <td>
                <a @click="setShowMailAddresses(setting)" class="has-icon">
                  <span class="icon">
                    <font-awesome-icon :icon="['fas', 'edit']" />
                  </span>
                  <span>Edit</span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </ui-component-modal>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import locationProvider from '@/providers/location'
import { cleanSource } from '@/utils/objectHelper'

export default {
  components: {
    ModalMailAddresses: () =>
      import('@/components/Locations/Settings/ModalMailAddresses'),
  },

  props: {
    settings: {
      type: Array,
      default: function () {
        return []
      },
    },

    selectedChannel: {
      type: Number,
      default: 0,
    },

    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      mSettings: cleanSource(this.settings),
      selectedKey: '',
      selectedMaiAddresses: [],
      showMailAddresses: false,
    }
  },

  computed: {
    ...mapState('locationStore', ['locationState']),
  },

  created() {},

  methods: {
    updateMailAddress(value) {
      let index = this.mSettings.findIndex((s) => s.Key === value.mailKey)

      if (index > -1) {
        this.mSettings[index].EmailAddress = value.addresses
      }
    },

    saveMailSettings() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        locationProvider.methods
          .saveLocationMailSettings(self.locationState.Id, self.mSettings)
          .then((response) => {
            if (response.status === 200) {
              self.isSavingSuccess = true
              self.$emit('mailSettingsUpdated', response.data)

              setTimeout(() => {
                self.onClickCancel()
              }, 1000)
            }
          })
          .catch((e) => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },

    setShowMailAddresses(setting) {
      this.selectedKey = setting.Key
      this.selectedMaiAddresses = setting.EmailAddress.split(',')

      this.showMailAddresses = true
    },

    hideModal() {
      this.selectedKey = ''
      this.selectedMaiAddresses = []

      this.showMailAddresses = false
    },
  },
}
</script>
